import { render, staticRenderFns } from "./userProfileEntityDocumentTemplates.vue?vue&type=template&id=6ff65a96&scoped=true"
import script from "./userProfileEntityDocumentTemplates.vue?vue&type=script&lang=js"
export * from "./userProfileEntityDocumentTemplates.vue?vue&type=script&lang=js"
import style0 from "./userProfileEntityDocumentTemplates.vue?vue&type=style&index=0&id=6ff65a96&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ff65a96",
  null
  
)

export default component.exports