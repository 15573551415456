<template>
  <section
    v-loading.fullscreen.lock="workflowsLoading"
    :element-loading-text="LoadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <el-card shadow="always" class="mt-1">
      <el-table border :data="entityWorkflows">
        <el-table-column label="Name">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="Primary User">
          <template v-if="primaryUser">
            {{ primaryUser }}
          </template>
        </el-table-column> -->
        <el-table-column label="Workflows Created">
          <template slot-scope="scope">
            <el-tag
              type="warning"
              size="mini"
              class="mr-1"
              @click="
                gotoLinkedDocuments(
                  scope.row,
                  scope.row._id,
                  'IN-PROGRESS',
                  scope.row.name
                )
              "
            >
              <el-tooltip placeholder="top-start" content="In Progress">
                <span class="fs-8">
                  {{ getInprogressCount(scope.row._id) }}
                </span>
              </el-tooltip>
            </el-tag>
            <el-tag
              type="success"
              size="mini"
              @click="
                gotoLinkedDocuments(
                  scope.row,
                  scope.row._id,
                  'COMPLETED',
                  scope.row.name
                )
              "
            >
              <el-tooltip placeholder="top-start" content="Completed">
                <span class="fs-8">
                  {{ getCompletedCount(scope.row._id) }}
                </span>
              </el-tooltip>
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column label="Last Modified">
          <template slot-scope="scope">
            {{ scope.row.updated_at | globalDateTimeFormat }}
          </template>
        </el-table-column>
        <el-table-column label="Actions" fixed="right">
          <template slot-scope="scope">
            <el-tooltip content="Send Workflow" placement="top"
              ><i
                class="el-icon-s-promotion ml-1 iconSty"
                @click="sendWorkflowToPrimaryUser(scope.row)"
              ></i
            ></el-tooltip>
            <el-tooltip content="View Attached Templates" placement="top"
              ><i
                class="el-icon-view ml-1 iconSty"
                @click="viewTemplates(scope.row)"
              ></i
            ></el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="display: flex; justify-content: center; align-items: center"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="paginationProps"
        :page-size="pageSize"
        layout="prev, pager, next, jumper, sizes, total"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <dialog-component
      :visible="showTemplatesInWF"
      title="Templates"
      @before-close="closeTemplatesInWF"
      custom-class="templates-dialog"
    >
      <div class="templates-container">
        <p>
          Templates Included In <b>'{{ workflowName }}'</b> Workflow
        </p>
        <div
          v-for="(temp, index) in templatesInWorkflows"
          :key="index"
          class="template-box"
        >
          <div slot="reference" class="doc-title" style="cursor: pointer">
            <p class="template-name">{{ temp.name }}</p>
          </div>
        </div>
      </div>
    </dialog-component>
    <dialog-component
      :visible="showWorkflowConfigUsers"
      title="Configure Workflow Users"
      @before-close="closeConfiguringModal"
    >
      <div
        class="el-row-wrapper"
        v-for="(user, index) in uniqueDocumentUsers"
        :key="index"
        :id="`user_${index}`"
      >
        <el-form v-if="user.type !== 'SENDER'">
          <el-row :gutter="10" class="m-3">
            <el-col :xl="1" :lg="1" :md="1" :sm="2" :xs="12">
              <el-tooltip
                effect="dark"
                content="Is primary user?"
                placement="top-start"
              >
                <el-checkbox
                  v-model="user.is_primary_user"
                  style="margin-top: 40px; margin-left: -5px"
                  border
                  class="mr-1"
                  @change="checkPrimaryUser(index)"
                  :disabled="checkBusinessOrNot"
                >
                </el-checkbox>
              </el-tooltip>
            </el-col>
            <el-col :xl="3" :lg="3" :md="3" :sm="6" :xs="12">
              <el-form-item label="Contact Type" prop="contact_type">
                <el-select
                  v-if="user && user.type && user.type !== 'SENDER'"
                  v-model="user.contact_type"
                  :disabled="true"
                >
                  <el-option
                    v-for="(type, index) in getAllContactTypes"
                    :key="index"
                    :value="type._id"
                    :label="type.name"
                  >
                    {{ type.name }}
                  </el-option>
                </el-select>
                <el-input
                  v-if="user.type === 'SENDER'"
                  v-model="senderCtype"
                  :disabled="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xl="5" :lg="5" :md="5" :sm="6" :xs="12">
              <el-form-item label="Email" prop="email">
                <el-select
                  autocomplete="off"
                  class="w-100"
                  placeholder="Search with Email"
                  suffix-icon="el-icon-search"
                  v-model="user.email"
                  clearable
                  @clear="resetEmail(index)"
                  filterable
                  :disabled="user.type === 'SENDER'"
                  @change="updateUserDetailsWithEmail(user)"
                >
                  <el-option
                    v-for="(contact, i) in getUserTypeContactsFiltered(index)"
                    :key="i"
                    :value="contact.email"
                    :label="contact.email"
                    :disabled="
                      fetchCurrentDocumentUsers.indexOf(contact.email) != -1
                    "
                  >
                    {{ contact.email }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xl="4" :lg="4" :md="4" :sm="8" :xs="12"> </el-col>
            <el-col :xl="4" :lg="4" :md="4" :sm="8" :xs="12">
              <el-form-item label="First Name" prop="first_name">
                <el-input
                  autocomplete="off"
                  v-model="user.first_name"
                  placeholder="First Name"
                  :disabled="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xl="4" :lg="4" :md="4" :sm="8" :xs="12">
              <el-form-item label="Last Name" prop="last_name">
                <el-input
                  autocomplete="off"
                  v-model="user.last_name"
                  placeholder="Last Name"
                  :disabled="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xl="2" :lg="2" :md="2" :sm="6" :xs="12">
              <el-form-item label="Role" prop="role">
                <el-input
                  autocomplete="off"
                  v-model="user.name"
                  placeholder="Role"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xl="4" :lg="4" :md="4" :sm="8" :xs="12">
              <el-form-item label="Signer type" prop="signer_type">
                <el-select
                  v-model="user.user_type"
                  placeholder="Select Signer Type"
                  :disabled="true"
                >
                  <el-option
                    v-for="(type, index) in ['SIGNER', 'CC', 'APPROVER']"
                    :key="index"
                    :value="type"
                    :label="type"
                    :disabled="type == 'CC'"
                  >
                    {{ type }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer">
        <el-button type="danger" @click="saveUsersAndSendWorkflow()"
          >Save & Send</el-button
        >
      </span>
    </dialog-component>
    <dialog-component
      :visible="viewWorkflowData"
      :title="workflowStatusTitle"
      class="ml-1 mr-1 mb-1"
      @before-close="closeViewWorkflowDataModal"
    >
      <data-tables-server
        :fullscreen="true"
        :total="total"
        :data="viewWorkflowsData"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :pagination-props="paginationProps"
        style="width: 100%"
        @query-change="loadData"
        class="workflows-table mt-1 mr-1"
      >
        <el-table-column
          label="Primary User"
          fixed="left"
          :width="getIsMobile ? 120 : 200"
        >
          <template slot-scope="scope">
            <p>{{ getPrimaryUserNameInEntity(scope.row) }}</p>
          </template>
        </el-table-column>

        <!-- <el-table-column
              label="Name"
              id="name"
              prop="title"
              :width="getIsMobile ? 130 : 250"
              fixed="left"
              sortable="custom"
              ><template slot-scope="scope">
                <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  :content="scope.row.name"
                >
                  <a
                    slot="reference"
                    class="doc-title"
                    @click="sendWorkflow(scope.row, 0)"
                  >
                    {{ scope.row.name | truncate(18, "...") }}
                  </a>
                </el-popover>
              </template>
              </el-table-column> -->

        <el-table-column
          label="Actions"
          prop="action"
          class="action"
          :width="getIsMobile ? 150 : 200"
        >
          <template slot-scope="scope">
            <ul class="action-buttons hover-buttons">
              <el-tooltip
                content="View Workflow"
                v-if="scope.row.workflow_status != 'DRAFT'"
              >
                <li>
                  <el-button
                    plain
                    type="danger"
                    size="mini"
                    class="px-2"
                    @click="openWorkflow(scope.row)"
                  >
                    <i class="el-icon-view"></i>
                  </el-button>
                </li>
              </el-tooltip>
              <el-tooltip content="Send Workflow" v-else>
                <li>
                  <el-button
                    plain
                    type="danger"
                    size="mini"
                    class="px-2"
                    @click="sendWorkflow(scope.row, 1)"
                  >
                    <i class="el-icon-position"></i>
                  </el-button>
                </li>
              </el-tooltip>
            </ul>
          </template>
        </el-table-column>

        <el-table-column
          prop="document_tracking_status.description"
          label="Current Status"
          :width="getIsMobile ? 150 : 250"
        >
          <template slot-scope="scope">
            <el-progress
              :percentage="getWorkflowStatus(scope.row)"
              :format="format"
              :width="10"
              v-if="scope.row.workflow_status != 'DRAFT'"
            >
            </el-progress>
            <p
              style="font-size: 10px"
              class="doc-subs"
              v-if="scope.row.workflow_status != 'DRAFT'"
            >
              {{ getFormat(scope.row) }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          label="Status"
          id="status"
          prop="title"
          sortable="custom"
          :width="getIsMobile ? 100 : 250"
        >
          <template slot-scope="scope" id="category">
            <div class="d-flex activestatus" v-if="scope.row.workflow_status">
              <div
                :class="scope.row.workflow_status.toLowerCase() + ' circle'"
              ></div>
              <div>
                <p>{{ scope.row.workflow_status }}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Last Modified"
          prop="last_modified"
          sortable="custom"
          :width="getIsMobile ? 150 : 250"
          ><template slot-scope="scope">{{
            scope.row.updated_at | globalDateTimeFormat
          }}</template></el-table-column
        >
      </data-tables-server>
    </dialog-component>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { postAPICall } from "@/helpers/httpHelper";
import { fetchEntityById } from "@/repo/entityRepo";

export default {
  name: "templates-PreviewTemplate",
  data() {
    return {
      templatesInWorkflows: [],
      showTemplatesInWF: false,
      uniqueDocumentUsers: [],
      showWorkflowConfigUsers: false,
      entityWorkflows: [],
      entityID: this.entityId,
      workflowsLoading: false,
      LoadingText: "",
      pageSize: 10,
      page: 1,
      currentPage: 1,
      allUsers: [],
      allUsersData: [],
      singleListUsers: [],
      getSelectedWorkflowUsers: [],
      workflowUserNameMapObj: {},
      senderCtype: "",
      currentEntity: {},
      entityDataId: null,
      contactDetails: "",
      workflowSettings: null,
      enforceOrder: false,
      workflowName: "",
      wfDataId: null,
      viewWorkflowData: false,
      workflowStatusTitle: "",
      viewWorkflowsData: [],
      total: 0,
      activeStep: 0,
      newWorkflowData: null,
      primaryUser: "",
    };
  },
  props: ["entityId", "entityData"],
  async mounted() {
    this.authid =
      this.getAuthenticatedUser && this.getAuthenticatedUser._id
        ? this.getAuthenticatedUser._id
        : null;
    let eId = this.$route?.params?.entity_id
      ? this.$route?.params?.entity_id
      : this.entityId;
    this.currentEntity = await fetchEntityById(eId);
    await this.fetchWorkflowsUsingEntityId();
    await Promise.all([
      this.$store.dispatch("contactTypes/fetchAllContactTypes", {
        get_all: true,
        type: "CONTACT_TYPE",
      }),
      this.$store.dispatch("contacts/fetchAllContacts", {
        get_all: true,
        type: "CONTACT",
      }),
    ]);
    if (
      this.currentEntity &&
      this.currentEntity.entity_type &&
      this.currentEntity.entity_type === "BUSINESS"
    ) {
      let params = {
        contactType: this.$route?.params?.entity_id,
        accountName: this.$route?.query?.dataId,
        limit: this.pageSize > 0 ? this.pageSize : 10,
        page: this.currentPage,
      };
      await this.$store.dispatch("contacts/fetchAllContactsByEntity", params);
    }
    let standardTemp = this.currentEntity?.templates.find(
      (e) =>
        e &&
        (e?.templateInfo?.type == "STANDARD" ||
          e?.templateInfo?.slug === "contactdetails")
    );
    const matchedTemplate = (this.entityData?.templates_data || []).find(
      (e) =>
        e?.template_data_id?.template_id &&
        e.template_data_id.template_id === standardTemp?.template_id
    );
    this.contactDetails = matchedTemplate
      ? matchedTemplate.template_data_id
      : undefined;
    if (
      this.contactDetails &&
      this.contactDetails.template_data &&
      this.contactDetails.template_data.first_name
    ) {
      this.primaryUser =
        this.contactDetails.template_data.first_name +
        " " +
        this.contactDetails.template_data.last_name;
    }
  },
  computed: {
    ...mapGetters("entities", [
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("templateWorkflow", [
      "getWorkflowCreatedData",
      "getWorkflowDataById",
      "getCreatedworkflowStatus",
      "getCreatedworkflowDataById",
      "getWorkflowsendStatus",
      "getWorkflowsCountById",
    ]),
    ...mapGetters("contacts", ["getAllContacts", "getContactTypeCreateStatus"]),
    ...mapGetters("contactTypes", [
      "getAllContactTypes",
      "getContactTypeCreateStatus",
      "getNewContactTypeData",
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
    ]),
    checkBusinessOrNot() {
      if (
        this.currentEntity &&
        this.currentEntity.entity_type &&
        this.currentEntity.entity_type === "BUSINESS"
      ) {
        return false;
      } else {
        return true;
      }
    },
    paginationProps() {
      return [5, 10, 20, 50];
    },
    fetchCurrentDocumentUsers() {
      return this.uniqueDocumentUsers.map((user) => user.email);
    },
    getPrimaryUserName() {
      let name = "";
      let name1 = "";
      if (
        this.contactDetails &&
        this.contactDetails.template_data &&
        this.contactDetails.template_data.first_name
      ) {
        name =
          this.contactDetails?.template_data?.first_name +
          " " +
          this.contactDetails?.template_data?.last_name;
        name1 =
          this.contactDetails?.first_name +
          " " +
          this.contactDetails?.last_name;
      }
      return name || name1;
    },
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.fetchWorkflowsUsingEntityId();
    },
    handleCurrentChange(val) {
      this.page = val ? val : 1;
      this.fetchWorkflowsUsingEntityId();
    },
    getPrimaryUserNameInEntity(data) {
      return data.primary_user.first_name + " " + data.primary_user.last_name;
    },
    checkPrimaryUser(index) {
      this.uniqueDocumentUsers.forEach((user, i) => {
        user.is_primary_user = i === index;
      });
      this.$forceUpdate();
    },
    async openWorkflow(data) {
      const queryString = new URLSearchParams({
        workflow_id: data.workflow_id,
      }).toString();
      const url = `/emp-documents/custom/${data.documents[0].document_id._id}?${queryString}`;
      window.open(url, "_blank");
    },
    async viewTemplates(data) {
      this.workflowName = data.name;
      let ids = data.templates_used.map((item) => item.template_id);
      let res = await postAPICall("POST", `/fieldsForWFinEntity`, { ids });
      this.templatesInWorkflows = res.data;
      this.showTemplatesInWF = true;
    },
    closeTemplatesInWF() {
      this.showTemplatesInWF = false;
    },
    closeViewWorkflowDataModal() {
      this.viewWorkflowData = false;
    },
    format() {
      let doc = "";
      return doc;
    },
    getFormat(data) {
      let format = "";
      if (data && data.total_documents && data.total_documents != "ARCHIVED") {
        format = data.completed_documents + "/" + data.total_documents;
      } else {
        format = "0/0";
      }
      return format;
    },
    getWorkflowStatus(data) {
      let percentage = 0;
      if (data && data.completed_documents && data.total_documents) {
        percentage = (data.completed_documents / data.total_documents) * 100;
      }
      return percentage;
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchWorkflowsUsingEntityId();
      }
    },
    async gotoLinkedDocuments(data, wId, step, name) {
      try {
        this.workflowsLoading = true;
        this.LoadingText = "Fetching Workflows Data...";
        let params = {
          limit: this.pageSize == 0 ? 10 : this.pageSize,
          page: this.page,
          id: wId,
        };
        if (step) {
          params.selected_status = step;
        }
        let res = await postAPICall("GET", `view-workflow/${wId}`, params);
        this.viewWorkflowsData = res.data;
        this.total = res.total;
        if (this.viewWorkflowsData.workflow_status === "SENT") {
          this.workflowStatusTitle = `${name}-In Progress Data`;
        } else {
          this.workflowStatusTitle = `${data.name}-Completed Data`;
        }
        this.workflowStatusTitle = "";
        this.viewWorkflowData = true;
        this.workflowsLoading = false;
      } catch (err) {
        this.workflowsLoading = false;
        console.log(err);
      }
    },
    async getWorkflowCount(ids) {
      try {
        let params = {
          workflowIds: ids,
        };
        await this.$store.dispatch(
          "templateWorkflow/fetchWorkflowsCountById",
          params
        );
      } catch (err) {
        console.log("getWorkflowCount Error", err);
      }
    },
    getCompletedCount(id) {
      let value = 0;
      if (this.getWorkflowsCountById && this.getWorkflowsCountById.length) {
        this.getWorkflowsCountById.forEach((doc) => {
          if (doc.workflow_id == id && doc.workflow_status == "COMPLETED") {
            value = value + 1;
          }
        });
      }
      return value;
    },
    getInprogressCount(id) {
      let value = 0;
      if (this.getWorkflowsCountById && this.getWorkflowsCountById.length) {
        this.getWorkflowsCountById.forEach((doc) => {
          if (doc.workflow_id == id && doc.workflow_status == "SENT") {
            value = value + 1;
          }
        });
      }
      return value;
    },
    closeConfiguringModal() {
      this.showWorkflowConfigUsers = false;
      this.workflowsLoading = false;
    },
    // checkPrimaryUser(index) {
    //   this.uniqueDocumentUsers.forEach((e, i) => {
    //     if (
    //       i != index &&
    //       this.uniqueDocumentUsers[index]["is_primary_user"] == true
    //     ) {
    //       e.is_primary_user = false;
    //     }
    //     if (
    //       this.uniqueDocumentUsers[index]["is_primary_user"] == false &&
    //       i == index
    //     ) {
    //       e.is_primary_user = true;
    //     }
    //   });
    // },
    async fillrecipientEmailData() {},
    async saveUsersAndSendWorkflow() {
      try {
        this.showWorkflowConfigUsers = false;
        this.workflowsLoading = true;
        this.LoadingText = "Preparing users to send the workflow...";
        this.uniqueDocumentUsers.forEach((user) => {
          const contact = this.getAllContacts.data.find(
            (c) => c.email === user.email
          );
          if (contact) {
            const matchedContactType = contact.contact_types.find(
              (ct) =>
                ct.contact_type && ct.contact_type._id === user.contact_type
            );
            if (matchedContactType) {
              user.contact = contact.contact_id || contact._id;
              user.contact_id = contact.contact_id || contact._id;
              user.entity_data_id = matchedContactType.account_data_id;
              user.first_name = contact.first_name || user.first_name;
              user.last_name = contact.last_name || user.last_name;
              user.address = contact.address || user.address;
              user.phone = contact.phone_number || user.phone;
              user.title = contact.title || user.title;
            }
          }
        });
        let singleListUsers = [];
        let allUsers = [];
        this.allUsersData.forEach((el) => {
          el.document_users.forEach((e) => {
            allUsers.push(e);
          });
        });
        this.uniqueDocumentUsers.forEach((user) => {
          allUsers.forEach((el) => {
            if (
              user.name == el.name &&
              user.contact_type == el.contact_type &&
              user.type != "SENDER" &&
              !user.constant_user
            ) {
              let userObj = { ...el };
              userObj.e_signature_order = user.e_signature_order;
              userObj.fields_required = user.fields_required;
              userObj.has_approval_access = user.has_approval_access;
              userObj.user_type = user.user_type;
              userObj.value = user.value;
              userObj.email = user.email;
              userObj.first_name = user.first_name;
              userObj.last_name = user.last_name;
              singleListUsers.push(userObj);
            }
          });
        });
        this.allUsersData.map((list) => {
          let users = [];
          singleListUsers.forEach((user) => {
            if (list._id == user.configurable_document_id) {
              users.push(user);
            }
          });
          let sender = list.document_users.find((e) => e.type == "SENDER");
          let constatUsers = list.document_users.filter(
            (e) => e.type != "SENDER" && e.constant_user
          );
          if (constatUsers && constatUsers.length) {
            constatUsers.forEach((e) => users.push(e));
          }
          sender.email = this.getAuthenticatedUser.email;
          sender.first_name = this.getAuthenticatedUser.first_name;
          sender.last_name = this.getAuthenticatedUser.last_name;
          sender.phone = this.getAuthenticatedUser.phone
            ? this.getAuthenticatedUser.phone
            : "";
          sender.title = this.getAuthenticatedUser.title
            ? this.getAuthenticatedUser.title
            : "";
          users.push(sender);
          users = users.sort(
            (a, b) =>
              parseInt(a.e_signature_order) - parseInt(b.e_signature_order)
          );
          list.document_users = users;
          users = [];
        });

        let ids = this.allUsersData.map((item) => item._id);
        let res = await postAPICall("POST", `/fieldsForWFinEntity`, { ids });
        this.allUsersData.forEach((e) => {
          res.data.forEach((el) => {
            if (e && e._id && el._id && e._id === el._id) {
              let documentData = this.prepareDocumentData(el.pages[0].fields);
              e.document_data = documentData;
              e.fields = el.pages[0].fields;
            }
          });
        });
        this.LoadingText = "Workflow Sending Is In Progress...";
        let params = {
          workflow_settings: this.workflowSettings,
          enforce_workflow_order: this.enforceOrder,
          finish_sender_side: false,
          name: this.workflowName,
          workflow_data_id: this.newWorkflowData._id,
          workflow_users: this.allUsersData,
          senderFilledAllFields: true,
          sender_time_zone: this.getTimeZone(),
        };
        console.log(params);
        await this.$store.dispatch("templateWorkflow/workflowSend", params);
        if (this.getWorkflowsendStatus) {
          this.workflowsLoading = false;
          this.$notify({
            title: "Success",
            message: "Workflow sent successfully",
            type: "success",
          });
          let wIds = this.entityWorkflows.flatMap((temp) => temp._id);
          await this.getWorkflowCount(wIds);
        }
      } catch (err) {
        this.workflowsLoading = false;
        console.log(err);
      }
    },
    getTimeZone() {
      let date1 = new Date();
      var sign = date1.getTimezoneOffset() > 0 ? "-" : "+";
      var offset = Math.abs(date1.getTimezoneOffset());
      var hours =
        Math.floor(offset / 60) < 10
          ? "0" + Math.floor(offset / 60)
          : Math.floor(offset / 60);
      var minutes = offset % 60 < 10 ? "0" + (offset % 60) : offset % 60;
      let timezoneoffset = sign + hours + ":" + minutes;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return timezone + ", UTC" + timezoneoffset;
    },
    prepareDocumentData(elements) {
      let data = {};
      elements.forEach((element) => {
        if (element.content) {
          data[element.key] = element.content;
        } else if (element.value) {
          data[element.key] = element.value;
        } else if (element.source) {
          data[element.key] = element.source;
        } else if (element.files && element.files.length) {
          data[element.key] = element.files;
        } else {
          data[element.key] = "";
        }
      });
      return data;
    },
    resetEmail(index) {
      this.uniqueDocumentUsers[index]["email"] = "";
      this.uniqueDocumentUsers[index]["first_name"] = "";
      this.uniqueDocumentUsers[index]["last_name"] = "";
      this.uniqueDocumentUsers[index]["is_primary_user"] = false;
    },
    updateUserDetailsWithEmail(user) {
      if (user && user.email && user.email !== "") {
        let userData = this.getAllContacts.data.find((el) => {
          return el.email === user.email;
        });
        this.uniqueDocumentUsers.forEach((e) => {
          if (
            this.currentEntity &&
            this.currentEntity?.entity_type !== "BUSINESS"
          ) {
            if (
              (this.contactDetails?.template_data?.email === e.email &&
                user.email === e.email) ||
              (this.contactDetails?.email === e.email && user.email === e.email)
            ) {
              e.is_primary_user = true;
            }
          }
          if (user && e && user.email === e.email) {
            e.first_name = userData.first_name ? userData.first_name : "";
            e.last_name = userData.last_name ? userData.last_name : "";
          }
        });
      }
    },
    async fetchWorkflowsUsingEntityId() {
      try {
        this.total = 0;
        let params = {
          limit: this.pageSize == 0 ? 10 : this.pageSize,
          page: this.page,
        };
        this.workflowsLoading = true;
        this.LoadingText = "Fetching Workflows...";
        let res1 = await postAPICall(
          "GET",
          `getWorkflowsByEntityId/${this.entityID}`,
          params
        );
        if (res1.data && res1.data.length) {
          this.entityWorkflows = res1.data;
          this.total = res1.total;
          this.currentPage = this.page;
          let wIds = this.entityWorkflows.flatMap((temp) => temp._id);
          await this.getWorkflowCount(wIds);
          this.workflowsLoading = false;
        } else {
          this.workflowsLoading = false;
          this.$message.error(res1.message);
        }
      } catch (err) {
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status === 404 &&
          err.response.data &&
          err.response.data.message
        ) {
          this.$message.error(err.response.data.message);
        }
        this.workflowsLoading = false;
      }
    },
    getUserTypeContactsFiltered(index) {
      if (this.uniqueDocumentUsers[index].contact_type) {
        return (this.getAllContacts?.data || []).filter((e) => {
          let search =
            !this.filterVal ||
            (this.filterVal && e.email.includes(this.filterVal));
          if (e?.contact_types && e.contact_types.length) {
            let found = e.contact_types.findIndex(
              (el) =>
                (el?.contact_type?._id &&
                  el.contact_type._id ==
                    this.uniqueDocumentUsers[index].contact_type) ||
                el.contact_type == this.uniqueDocumentUsers[index].contact_type
            );
            return found == -1 ? false : search ? true : false;
          } else {
            return (
              e.contact_type &&
              e.contact_type._id ==
                this.uniqueDocumentUsers[index].contact_type &&
              search
            );
          }
        });
      } else {
        if (this.getAllContacts && this.getAllContacts?.data) {
          return this.getAllContacts.data.filter(
            (e) =>
              !this.filterVal ||
              (this.filterVal && e.email.includes(this.filterVal))
          );
        }
        return [];
      }
    },
    async sendWorkflowToPrimaryUser(data) {
      try {
        this.workflowsLoading = true;
        this.LoadingText = "Creating Workflow Data...";
        if (
          (this.currentEntity &&
            this.currentEntity.entity_type &&
            this.currentEntity.entity_type !== "BUSINESS" &&
            !this.$route?.query?.dataId) ||
          (this.currentEntity &&
            this.currentEntity.entity_type &&
            this.currentEntity.entity_type === "BUSINESS" &&
            this.getAllContacts &&
            this.getAllContacts.data &&
            this.getAllContacts.data?.length === 0)
        ) {
          this.$message.error(
            this.currentEntity.entity_type === "BUSINESS"
              ? "Please add contacts to proceed with the process...!"
              : "Please add contact details to proceed with the process...!"
          );
          this.workflowsLoading = false;
        } else {
          let templates_used = [];
          this.entityWorkflows.forEach((e) => {
            e.templates_used.forEach((el, index) => {
              templates_used.push({
                template_id: el.configurable_document_id,
                document_order: index + 1,
                type: "DOCUMENT",
              });
            });
          });
          let params1 = {
            workflow_id: data._id,
            enforce_workflow_order: data.enforce_workflow_order,
            templates_used: templates_used,
            name: data.name,
            workflow_settings: data.settings,
            workflow_status: "DRAFT",
          };
          let res = await postAPICall(
            "POST",
            `/create-workflow-data/${data._id}`,
            params1
          );
          this.newWorkflowData = res.data;
          this.LoadingText = "Preparing Workflow Users...";
          let workflowData = await postAPICall(
            "Get",
            `/getWorkflowData/${data._id}`
          );
          let params = {
            templates_used: workflowData.data.templates_used.flatMap(
              (e) => e?.template_id?._id || e?.template_id || e?._id
            ),
          };
          this.enforceOrder = workflowData.data.enforce_workflow_order;
          this.workflowSettings = workflowData.data.workflow_settings;
          this.workflowName = workflowData.data.name;
          this.wfDataId = workflowData.data._id;
          let users = await postAPICall("POST", "/workflow/users", params);
          await Promise.all([
            this.$store.dispatch("contactTypes/fetchAllContactTypes", {
              get_all: true,
              type: "CONTACT_TYPE",
            }),
            this.$store.dispatch("contacts/fetchAllContacts", {
              get_all: true,
              type: "CONTACT",
            }),
          ]);
          this.uniqueDocumentUsers = [];
          const seenUsers = new Map();
          users.data.forEach((item) => {
            item.document_users.forEach((user) => {
              const uniqueKey = `${user.contact_type}_${user.name}`;
              if (!seenUsers.has(uniqueKey)) {
                seenUsers.set(uniqueKey, true);
                this.uniqueDocumentUsers.push(user);
              }
            });
          });
          if (this.currentEntity && this.currentEntity !== "BUSINESS") {
            const contactTypeGroups = new Map();
            this.uniqueDocumentUsers.forEach((user) => {
              if (!contactTypeGroups.has(user.contact_type)) {
                contactTypeGroups.set(user.contact_type, []);
              }
              contactTypeGroups.get(user.contact_type).push(user);
            });
            contactTypeGroups.forEach((users, contactType) => {
              if (contactType === this.$route?.params?.entity_id) {
                if (users.length === 1) {
                  users[0].is_primary_user = true;
                } else {
                  users.forEach((user) => {
                    user.is_primary_user = false;
                  });
                }
              } else {
                users.forEach((user) => {
                  user.is_primary_user = false;
                });
              }
            });
          }
          const matchedUsers = users.data.filter((userObj) => {
            return workflowData.data.templates_used.some((templateUsed) => {
              return templateUsed.template_id._id === userObj._id;
            });
          });
          let contactUsers = workflowData.data.workflow_users;
          const matchedUsersWithContacts = matchedUsers.map((documentObj) => {
            const documentUsers = documentObj.document_users;
            const mappedUsers = documentUsers.map((documentUser) => {
              if (!documentUser.email || documentUser.email === "") {
                const matchedContact = contactUsers.find((contactUser) => {
                  return contactUser.name === documentUser.name;
                });
                if (matchedContact) {
                  return {
                    ...documentUser,
                    email: matchedContact.email,
                    first_name: matchedContact.first_name,
                    last_name: matchedContact.last_name,
                  };
                }
              }
              return documentUser;
            });
            return {
              ...documentObj,
              document_users: mappedUsers,
            };
          });
          await this.allUsersData.map((list) => {
            let users = [];
            matchedUsersWithContacts.forEach((user) => {
              if (list._id == user.configurable_document_id) {
                users.push(user);
              }
            });
            let sender = list.document_users.find((e) => e.type == "SENDER");
            let constatUsers = list.document_users.filter(
              (e) => e.type != "SENDER" && e.constant_user
            );
            if (constatUsers && constatUsers.length) {
              constatUsers.forEach((e) => users.push(e));
            }
            sender.email = this.getAuthenticatedUser.email;
            sender.first_name = this.getAuthenticatedUser.first_name;
            sender.last_name = this.getAuthenticatedUser.last_name;
            sender.phone = this.getAuthenticatedUser.phone
              ? this.getAuthenticatedUser.phone
              : "";
            sender.title = this.getAuthenticatedUser.title
              ? this.getAuthenticatedUser.title
              : "";
            users.push(sender);
            users = users.sort(
              (a, b) =>
                parseInt(a.e_signature_order) - parseInt(b.e_signature_order)
            );
            list.document_users = users;
            users = [];
          });
          this.allUsersData = matchedUsersWithContacts;
          console.log(this.uniqueDocumentUsers, this.allUsersData, "1136");
          this.workflowsLoading = false;
          this.showWorkflowConfigUsers = true;
        }
      } catch (err) {
        this.workflowsLoading = false;
        console.log(err);
      }
    },
    getUserTypeContacts(contact_type) {
      if (this.getAllContacts && this.getAllContacts.data) {
        if (contact_type) {
          let id = contact_type._id ? contact_type._id : contact_type;
          return this.getAllContacts.data.filter((e) => {
            if (e?.contact_types && e.contact_types.length) {
              let found = e.contact_types.findIndex(
                (el) =>
                  (el?.contact_type?._id && el.contact_type._id == id) ||
                  el.contact_type == id
              );
              return found == -1 ? false : true;
            } else {
              return e.contact_type && e.contact_type._id == id;
            }
          });
        } else {
          return this.getAllContacts.data;
        }
      }
      return [];
    },
  },
};
</script>
<style scoped>
.iconSty {
  cursor: pointer;
}
.vertical-icon {
  transform: rotate(90deg);
  display: inline-block;
}
.doc-subs {
  margin-top: -13px;
  font-weight: 300;
  text-align: right;
  margin-right: 9%;
}
.templates-dialog {
  max-width: 600px;
  border-radius: 8px;
  overflow: hidden;
}

.templates-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
}
.template-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  transition: box-shadow 0.2s, transform 0.2s;
}

.template-box:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

/* Title styling */
.doc-title {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
}

.template-name {
  margin: 0;
  font-size: 0.9rem;
  color: #555;
}
</style>
